<template>
  <b-card no-body>
    <b-overlay
      variant="white"
      :show="false"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
    <b-card-header>
      <b-card-title>Bookings - Module wise</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        v-if="isLoaded"
        :height="150"
        :data="chartModuleData"
        :options="chartOptions"
      />
    </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BOverlay
} from 'bootstrap-vue'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'
import chartColors from './ChartThemeColors'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { sum } from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentBarChart,
    StatisticCardHorizontal,
    BOverlay,
  },
  props: {
    chartData: {
      type: Object
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    modules: {
      type: Array,
      default: ['Hotel', 'Flight', 'Hotel + Flight',]
    }
  },

  // data() {},
  computed: {
    chartModuleData() {
      let colorData = this.$store.state.auth.configData;
      const modules = this.modules.map(e=> e.name );
      modules.push('');
      modules.push('');
      const countData = [this.chartData?.hotel_flight, this.chartData?.hotel, this.chartData?.flight ,'','']
      return {
        labels: modules,
        datasets: [
          {
            data: sum(countData) > 0 ? countData : [],
            backgroundColor: ['#FFA500', '#4ec7e6', '#8bc34a'],
            borderColor: 'transparent',
          },
        ],
      }
    },
    chartOptions() {
      // const maxBooking = this.chartData.hotel > this.chartData.hotel_flight ? this.chartData.hotel : this.chartData.hotel_flight;
      // const maxWidth = Math.round((maxBooking / Math.pow(10, Math.log10(maxBooking))) * ((""+maxBooking).length * 100));
      // const stepSize = maxWidth / 10;
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                // stepSize,
                // min: 0,
                // max: maxWidth,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>